import React, { useEffect, useMemo, useReducer } from "react";

import { useAuthenticator } from "@aws-amplify/ui-react";
import { AppLayout, Form, Textarea } from "@cloudscape-design/components";
import { Auth } from "aws-amplify";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import {
  DEFAULT_SKILL_DROPDOWN_LABEL,
  DEFAULT_SKILL_DROPDOWN_VALUE,
  FIN_QNA_SKILL_ID,
  skillsMapping,
} from "../common/constants";
import ChatContent from "../components/chatbot/ChatContent";
import { HomeActions } from "../components/chatbot/HomeActions";
import { ChatMessageProps } from "../components/chatbot/message/ChatMessage";
import Acknowledgement from "../components/layout/AcknowledgementPopup";
import AppHeader from "../components/layout/AppHeader";
import "./style.scss";
import { Header } from "../components/layout/header/Header";
import SideNav from "../components/navigation/side-navigation/SideNavigation";
import { getFinQAMetadata, lambdaUrlStreaming } from "../utils/ApiHelper";
import { getAcknowledgement, getSkills, isNotValidSkill, populateIntroTextForSkill } from "../utils/CommonUtils";
import { getUserAttribute } from "../utils/user";

export interface HomeProps {
  page?: string;
}

const initialState = {
  finneySkills: [],
  introText: "",
  isLoading: false,
  debugMessages: [],
  userTask: "",
  question: "",
  chatbotPersona: {
    label: DEFAULT_SKILL_DROPDOWN_VALUE,
    value: DEFAULT_SKILL_DROPDOWN_VALUE,
  },
  smartRoutingDetectedSkillLabel: "",
  smartRoutingDetectedSkillValue: "",
  isSkillLocked: false,
  conversationId: "",
  chatPanelContents: "",
  finqnaMetadata: [],
  businessTenants: [],
  businessTenantSelected: "Please Select",
  docTypes: [],
  docTypeSelected: "Please Select",
  docs: [],
  docSelected: "ALL",
  acknowledged: true,
  messageHistory: [],
  messageCount: 0,
  autoSpeak: false,
  navigationOpen: true,
};

function reducer(state: any, action: any) {
  switch (action.type) {
    case "GET_FINNEY_SKILLS":
      return { ...state, finneySkills: action.finneySkills };
    case "SET_INTRO_TEXT":
      return { ...state, introText: action.introText };
    case "SET_USER_TASK":
      return { ...state, userTask: action.userTask };
    case "SET_QUESTION":
      return { ...state, question: action.question };
    case "SET_CHATBOT_PERSONA":
      return { ...state, chatbotPersona: action.chatbotPersona };
    case "SET_CHAT_PANEL_CONTENTS":
      return { ...state, chatPanelContents: action.chatPanelContents };
    case "SET_FINQNA_METADATA":
      return { ...state, finqnaMetadata: action.finqnaMetadata };
    case "SET_BUSINESS_TENANTS":
      return { ...state, businessTenants: action.businessTenants };
    case "SET_BUSINESS_TENANT_SELECTED":
      return { ...state, businessTenantSelected: action.businessTenantSelected };
    case "SET_DOC_TYPES":
      return { ...state, docTypes: action.docTypes };
    case "SET_DOC_TYPE_SELECTED":
      return { ...state, docTypeSelected: action.docTypeSelected };
    case "SET_DOCS":
      return { ...state, docs: action.docs };
    case "SET_DOC_SELECTED":
      return { ...state, docSelected: action.docSelected };
    case "SET_ACKNOWLEDGED":
      return { ...state, acknowledged: action.acknowledged };
    case "ADD_TO_MESSAGE_HISTORY":
      return { ...state, messageHistory: [...state.messageHistory, action.message] };
    case "SET_AUTO_SPEAK":
      return { ...state, autoSpeak: action.autoSpeak };
    case "SET_IS_LOADING":
      return { ...state, isLoading: action.isLoading };
    case "RESET_MESSAGE_COUNT":
      return { ...state, messageCount: 0 };
    case "SET_DEBUG_MESSAGE":
      return { ...state, debugMessages: [...state.debugMessages, action.debugMessage] };
    case "RESET_DEBUG_MESSAGES":
      return { ...state, debugMessages: [] };
    case "SET_SMART_ROUTING_SKILL_LABEL":
      return { ...state, smartRoutingDetectedSkillLabel: action.smartRoutingDetectedSkillLabel };
    case "SET_SMART_ROUTING_SKILL_VALUE":
      return { ...state, smartRoutingDetectedSkillValue: action.smartRoutingDetectedSkillValue };
    case "SET_CONVERSATION_ID":
      return { ...state, conversationId: action.conversationId };
    case "SET_IS_SKILL_LOCKED":
      return { ...state, isSkillLocked: action.isSkillLocked };
    case "SET_MESSAGE_COUNT":
      return { ...state, messageCount: action.messageCount };
    case "SET_MESSAGE_HISTORY":
      return { ...state, messageHistory: action.messageHistory };
    case "SET_NAVIGATION_OPEN":
      return { ...state, navigationOpen: action.navigationOpen };
    default:
      return state;
  }
}

export default function Home(props: HomeProps) {
  const { user } = useAuthenticator((context) => [context.user]);
  const userData = useMemo(() => getUserAttribute(user), [user]);
  const { skillId } = useParams();
  const navigate = useNavigate();
  const [state, dispatch] = useReducer(reducer, initialState);
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const autoSpeakFromUrl = searchParams.get("autoSpeak");

  useEffect(() => {
    // Check if the user is authenticated
    const checkUserAuthentication = async () => {
      try {
        await Auth.currentAuthenticatedUser();
      } catch (error) {
        // If the user is not authenticated, sign them back in
        try {
          await Auth.federatedSignIn({ customProvider: "AmazonFederate" });
        } catch (signInError) {
          console.error("Failed to sign in:", signInError);
        }
      }
    };

    checkUserAuthentication();
  }, []);

  useEffect(() => {
    if (skillId) {
      populateIntroTextForSkill(
        skillId,
        (chatbotPersona) => dispatch({ type: "SET_CHATBOT_PERSONA", chatbotPersona: chatbotPersona }),
        (introText) => dispatch({ type: "SET_INTRO_TEXT", introText: introText }),
        (userTask) => dispatch({ type: "SET_USER_TASK", userTask: userTask })
      );
    } else {
      dispatch({
        type: "SET_INTRO_TEXT",
        introText: skillsMapping[DEFAULT_SKILL_DROPDOWN_VALUE].skillDescription,
      });
      dispatch({
        type: "SET_CHATBOT_PERSONA",
        chatbotPersona: {
          label: DEFAULT_SKILL_DROPDOWN_LABEL,
          value: DEFAULT_SKILL_DROPDOWN_VALUE,
        },
      });
    }
  }, [skillId]);

  useEffect(() => {
    if (skillId) {
      populateIntroTextForSkill(
        skillId,
        (chatbotPersona) => dispatch({ type: "SET_CHATBOT_PERSONA", chatbotPersona: chatbotPersona }),
        (introText) => dispatch({ type: "SET_INTRO_TEXT", introText: introText }),
        (userTask) => dispatch({ type: "SET_USER_TASK", userTask: userTask })
      );
    }
    dispatch({ type: "SET_MESSAGE_HISTORY", messageHistory: [] });
    getSkills((finneySkills: any) => dispatch({ type: "GET_FINNEY_SKILLS", finneySkills: finneySkills }));
    getFinQAMetadata(
      userData.alias,
      (finqnaMetadata) => dispatch({ type: "SET_FINQNA_METADATA", finqnaMetadata: finqnaMetadata }),
      (businessTenants) => dispatch({ type: "SET_BUSINESS_TENANTS", businessTenants: businessTenants })
    );
    getAcknowledgement((acknowledged: any) => dispatch({ type: "SET_ACKNOWLEDGED", acknowledged: acknowledged }));
  }, []);

  useEffect(() => {
    dispatch({
      type: "SET_AUTO_SPEAK",
      autoSpeak: autoSpeakFromUrl === "true",
    });
  }, [autoSpeakFromUrl]);

  /**
   * Function to initiate a new conversation.
   * When a new conversation is trigerred, it resets all state variables.
   */
  function handleNewConversation() {
    dispatch({ type: "SET_USER_TASK", userTask: "" });
    dispatch({ type: "SET_QUESTION", question: "" });
    dispatch({ type: "SET_CONVERSATION_ID", conversationId: "" });
    dispatch({ type: "SET_IS_SKILL_LOCKED", isSkillLocked: false });
    dispatch({ type: "SET_SMART_ROUTING_SKILL_LABEL", smartRoutingDetectedSkillLabel: "" });
    dispatch({ type: "SET_SMART_ROUTING_SKILL_VALUE", smartRoutingDetectedSkillValue: "" });
    dispatch({ type: "SET_IS_LOADING", isLoading: false });
    navigate(`/skills/${state.chatbotPersona.value}?autoSpeak=${state.autoSpeak}`);
    location.reload();
  }

  // == Handlers
  /**
   * Call backend LLM wrapper
   * @returns
   */
  const processUserTask = async () => {
    console.log("User: {}", user);
    console.log("UserData: {}", userData);
    try {
      if (!user) {
        console.log("User: {}", user);
        await Auth.currentAuthenticatedUser();
        useMemo(() => getUserAttribute(user), [user]);
      }
    } catch (error) {
      console.error("Error refreshing user authentication state:", error);
    }

    if (!state.question) {
      alert("Please enter a question/task. Looks like you did not put in anything for Finney.");
      return;
    }

    dispatch({ type: "SET_IS_LOADING", isLoading: true });
    dispatch({ type: "SET_USER_TASK", userTask: "" });
    dispatch({ type: "SET_QUESTION", question: "" });
    dispatch({ type: "SET_MESSAGE_COUNT", messageCount: state.messageCount + 1 });
    let isSmartRoutedQuestion = false;
    if (state.chatbotPersona.value === "smart-routing") {
      isSmartRoutedQuestion = true;
    } else {
      dispatch({ type: "SET_IS_SKILL_LOCKED", isSkillLocked: true });
    }

    const messageHistory = [...state.messageHistory];
    messageHistory.push({
      autoSpeak: state.autoSpeak,
      messageCount: state.messageCOunt,
      timestamp: new Date().toISOString(),
      message: state.question,
      skill: state.chatbotPersona.label,
      messageId: `dummy-id-${Date.now()}`, // fake it because it does not exist yet
      conversationId: state.conversationId,
      staticReferenceSources: [],
      referencedSources: [],
      citations: [],
      owner: {
        isSelf: true,
        isAI: false,
        avatarUrl: `https://internal-cdn.amazon.com/badgephotos.amazon.com/?uid=${userData.alias}`,
        name: userData.alias + "@",
        alias: userData.alias,
      },
    } as ChatMessageProps);
    dispatch({ type: "ADD_TO_MESSAGE_HISTORY", message: messageHistory[messageHistory.length - 1] });
    let chatPanelDom = document.getElementById("chat-panel")!;
    chatPanelDom.scrollTop = chatPanelDom.scrollHeight;

    const userInput = state.question;
    const start = Date.now();
    dispatch({
      type: "SET_DEBUG_MESSAGE",
      debugMessage: `${new Date().toLocaleString()} - CLIENT - INFO - Sent message to Finney "${userInput.substring(
        0,
        36
      )}..."`,
    });
    try {
      const { messageId, _conversationId, finalAnswer, referencedSources, smartRoutingDetectedSkill } =
        await lambdaUrlStreaming(
          userInput,
          state.conversationId,
          isNotValidSkill(state.smartRoutingDetectedSkillValue)
            ? state.chatbotPersona.value
            : state.smartRoutingDetectedSkillValue,
          (chatPanelContents) => dispatch({ type: "SET_CHAT_PANEL_CONTENTS", chatPanelContents: chatPanelContents }),
          state.chatPanelContents,
          (debugMessage) => dispatch({ type: "SET_DEBUG_MESSAGE", debugMessage: debugMessage }),
          state.autoSpeak,
          state.businessTenantSelected,
          state.docTypeSelected,
          state.docSelected
        );
      dispatch({ type: "SET_CONVERSATION_ID", conversationId: _conversationId });
      if (isSmartRoutedQuestion && !isNotValidSkill(smartRoutingDetectedSkill)) {
        const smartRoutedSkill = skillsMapping[smartRoutingDetectedSkill];
        dispatch({
          type: "SET_SMART_ROUTING_SKILL_LABEL",
          smartRoutingDetectedSkillLabel: smartRoutedSkill.skillName,
        });
        dispatch({ type: "SET_SMART_ROUTING_SKILL_VALUE", smartRoutingDetectedSkillValue: smartRoutedSkill.skillId });
        if (smartRoutingDetectedSkill === FIN_QNA_SKILL_ID) {
          dispatch({ type: "SET_QUESTION", question: userInput });
        }
      }
      const messageHistory = [...state.messageHistory];
      messageHistory.push({
        messageId: messageId,
        autoSpeak: state.autoSpeak,
        messageCount: state.messageCOunt,
        isLoading: state.isLoading,
        conversationId: state.conversationId,
        message: finalAnswer,
        skill: isNotValidSkill(state.smartRoutingDetectedSkillLabel)
          ? state.chatbotPersona.label
          : state.smartRoutingDetectedSkillLabel,
        timestamp: new Date().toISOString(),
        citations: [],
        staticReferenceSources:
          state.chatbotPersona.value === "sna-web-mec"
            ? ["https://w.amazon.com/bin/view/FOX/Core/Products/MEC/UserGuide/Home"]
            : [],
        referencedSources: referencedSources,
        owner: {
          isSelf: false,
          isAI: true,
          avatarUrl: `https://internal-cdn.amazon.com/badgephotos.amazon.com/?uid=${userData.alias}`,
          name: userData.alias + "@",
          alias: userData.alias,
        },
      } as ChatMessageProps);
      dispatch({ type: "ADD_TO_MESSAGE_HISTORY", message: messageHistory[messageHistory.length - 1] });
      chatPanelDom.scrollTop = chatPanelDom.scrollHeight;

      const duration = ((Date.now() - start) / 1000).toFixed(2);
      dispatch({
        type: "SET_DEBUG_MESSAGE",
        debugMessage: `${new Date().toLocaleString()} - CLIENT - INFO - Done. Took ${duration} seconds to get answer from LLM\n`,
      });
    } catch (error) {
      console.warn("Failed to parse LLM answer, error was", error);
      const errorMsg = `${new Date().toLocaleString()} - CLIENT - ERROR - Failed to parse LLM answer, error was: ${error}\n`;
      dispatch({ type: "SET_DEBUG_MESSAGE", debugMessage: errorMsg });
      dispatch({ type: "SET_IS_LOADING", isLoading: false });
    }
    dispatch({ type: "SET_IS_LOADING", isLoading: false });
  };

  const checkButtonDisable = useMemo(() => {
    return (skillId: string): boolean => {
      if (state.isLoading || state.question === "" || state.messageCount >= 50) {
        return true;
      } else if (skillId === FIN_QNA_SKILL_ID || state.smartRoutingDetectedSkillValue === FIN_QNA_SKILL_ID) {
        return state.businessTenantSelected === "Please Select" || state.docTypeSelected === "Please Select";
      } else {
        return false;
      }
    };
  }, [state.isLoading, state.question, props.page, state.businessTenantSelected, state.docTypeSelected]);
  return (
    <>
      <Acknowledgement
        acknowledged={state.acknowledged}
        setAcknowledged={(acknowledged) => dispatch({ type: "SET_ACKNOWLEDGED", acknowledged: acknowledged })}
      />
      <div id="b">
        <div id="h" style={{ position: "fixed", top: 0, zIndex: 1002, width: "100vw" }}>
          <AppHeader chatbotPersona={state.chatbotPersona} page={props.page} userAlias={userData.alias}></AppHeader>
        </div>
      </div>
      <AppLayout
        disableContentPaddings={false}
        toolsHide={true}
        maxContentWidth={Number.MAX_VALUE}
        navigationOpen={state.navigationOpen}
        onNavigationChange={(event) => dispatch({ type: "SET_NAVIGATION_OPEN", navigationOpen: !state.navigationOpen })}
        navigation={
          <SideNav
            state={state}
            dispatch={dispatch}
            debugMessage={state.debugMessage}
            messageHistory={state.messageHistory}
            autoSpeak={state.autoSpeak}
            skills={state.finneySkills}
            userAlias={userData.alias}
            isSkillLocked={state.isSkillLocked}
            conversationId={state.conversationId}
            messageCount={state.messageCount}
            smartRoutingDetectedSkillLabel={state.smartRoutingDetectedSkillLabel}
          />
        }
        content={
          <div style={{ minHeight: "100vh", height: "100%" }}>
            <div style={{ paddingTop: 40 }}>
              <Header
                category="Welcome to"
                title={state.chatbotPersona.value === "smart-routing" ? "Finney" : state.chatbotPersona.label}
                subtitle={skillsMapping[state.chatbotPersona.value].skillSubTitle}
                description={skillsMapping[state.chatbotPersona.value].skillHeaderDescription}
              />
            </div>
            <Form
              actions={
                <div>
                  <HomeActions
                    state={state}
                    dispatch={dispatch}
                    processUserTask={processUserTask}
                    handleNewConversation={handleNewConversation}
                    smartRoutingDetectedSkillLabel={state.smartRoutingDetectedSkillLabel}
                    checkButtonDisable={checkButtonDisable}
                    navigate={navigate}
                    finneySkills={state.finneySkills}
                    autoSpeak={state.autoSpeak}
                    businessTenants={state.businessTenants}
                    businessTenantSelected={state.businessTenantSelected}
                    docTypes={state.docTypes}
                    docTypeSelected={state.docTypeSelected}
                    docSelected={state.docSelected}
                    docs={state.docs}
                  />
                </div>
              }
            >
              <ChatContent
                state={state}
                dispatch={dispatch}
                smartRoutingDetectedSkillLabel={state.smartRoutingDetectedSkillLabel}
                isLoading={state.isLoading}
                messageCount={state.messageCount}
              />
            </Form>

            {/* What's going on? -- Display Debug/Trace messages */}
            <div style={{ paddingBottom: 16, paddingTop: 0 }}>
              <h4>What's Going On?</h4>
              <Textarea
                id="debug-messages-panel"
                name="debugMessages"
                value={state.debugMessages.join("\n")}
                rows={4}
                readOnly
              ></Textarea>
            </div>
          </div>
        }
      ></AppLayout>
    </>
  );
}
