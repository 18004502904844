import { invokeConversationEndpoint, invokeMetadataEndpoint } from "./ApiHelper";
import { DEFAULT_SKILL_DROPDOWN_LABEL, DEFAULT_SKILL_DROPDOWN_VALUE, skillsMapping } from "../common/constants";

/**
 * Populates the Intro Text and Example Task based on the skill
 * @param skillId
 * @param setChatbotPersona
 * @param setIntroText
 * @param setUserTask
 */
/* istanbul ignore next */
export function populateIntroTextForSkill(
  skillId: string,
  setChatbotPersona: (arg0: { label: any; value: string }) => void,
  setIntroText: (arg0: any) => void,
  setUserTask: (arg0: any) => void
) {
  const skill = skillsMapping[skillId];
  if (skill) {
    setChatbotPersona({ label: skill.skillName, value: skillId });
    setIntroText(skill.skillDescription);
    setUserTask(skill.exampleTask);
  }
}

/**
 * Retrieves available skills for web when page loads
 */
/* istanbul ignore next */
export async function getSkills(setFinneySkills: any) {
  const skills = await invokeMetadataEndpoint("list-available-skills");
  const finneySkills = transformAvailableSkillsToDropdownObjects(skills);
  setFinneySkills(finneySkills);
}

/* istanbul ignore next */
function transformAvailableSkillsToDropdownObjects(
  data: { skillName: string; skillId: string }[]
): { label: string; value: string }[] {
  let availableSkills = [];
  availableSkills.push({ label: DEFAULT_SKILL_DROPDOWN_LABEL, value: DEFAULT_SKILL_DROPDOWN_VALUE });
  return availableSkills.concat(
    data.map((item) => ({
      label: item.skillName,
      value: item.skillId,
    }))
  );
}

/**
 * Retrieves the status of user disclaimer acknowledgement
 */
/* istanbul ignore next */
export async function getAcknowledgement(setAcknowledged: any) {
  const acknowledgement = (await invokeMetadataEndpoint("get-acknowledgement")).body;
  setAcknowledged(acknowledgement.acknowledged);
}

/**
 * Function to check is the skill has a valid text and not empty.
 */
export const isNotValidSkill = (skill: any) => {
  return skill === null || skill === "-" || skill === undefined || skill === "";
};

/**
 * Retrieves the conversation history of the user
 */
/* istanbul ignore next */
export async function getConversationHistory(startDate: string, endDate: string, pageIndex: string, pageSize: string) {
  const conversationsResponse = await invokeConversationEndpoint(
    "get-conversations",
    pageIndex,
    pageSize,
    "", // This represents empty conversationId for get-conversations action
    startDate,
    endDate
  );
  return conversationsResponse;
}

/**
 * Retrieves the messages from a conversation
 */
/* istanbul ignore next */
export async function getMessagesByConversationId(conversationId: string, pageIndex: string, pageSize: string) {
  const messagesResponse = await invokeConversationEndpoint("get-messages", pageIndex, pageSize, conversationId);
  return messagesResponse;
}
