import { getStage, REGION } from "../common/constants";

export const ALPHA_DOMAIN = "alpha";
export const BETA_DOMAIN = "beta";
export const PROD_DOMAIN = "prod";
export const PERSONEL_DOMAIN = "vigrat";

export const envConfig = {
  Auth: {
    identityPoolId: getIdentityPoolId(getStage()),
    region: REGION,
    userPoolId: getUserPoolId(getStage()),
    userPoolWebClientId: getUserPoolWebClientId(getStage()),
    mandatorySignIn: true,
    cookieStorage: {
      // REQUIRED - Cookie domain (only required if cookieStorage is provided)
      domain: `${window.location.hostname}`,
      // OPTIONAL - Cookie path
      path: "/",
      // OPTIONAL - Cookie expiration in days
      expires: 365,
      // OPTIONAL - Cookie secure flag
      secure: true,
    },
    // (optional) - Hosted UI configuration
    oauth: {
      domain: getCognitoDomain(getStage()),
      scope: ["openid"],
      redirectSignIn: getRedirectUrl(getStage()),
      redirectSignOut: getRedirectUrl(getStage()),
      responseType: "code",
    },
  },
};

/**
 * @param stage
 * @return cognito domain for the stage
 */
export function getCognitoDomain(stage: string): string {
  switch (stage.toLowerCase()) {
    case PERSONEL_DOMAIN:
      return "finney-vigrat.auth.us-east-1.amazoncognito.com";
    case ALPHA_DOMAIN:
      return "finney-alpha.auth.us-east-1.amazoncognito.com";
    case BETA_DOMAIN:
      return "finney-beta.auth.us-east-1.amazoncognito.com";
    case PROD_DOMAIN:
      return "finney-prod.auth.us-east-1.amazoncognito.com";
    default:
      return "localhost:3000"; // <-- Dev Account updates here
  }
}

/**
 * @param stage
 * @return redirect url for the stage
 */
export function getRedirectUrl(stage: string): string {
  switch (stage.toLowerCase()) {
    case PERSONEL_DOMAIN:
      return "https://vigrat.finney.finergy.amazon.dev/";
    case ALPHA_DOMAIN:
      return "https://alpha.finney.finergy.amazon.dev/";
    case BETA_DOMAIN:
      return "https://beta.finney.finergy.amazon.dev/";
    case PROD_DOMAIN:
      return "https://prod.finney.finergy.amazon.dev/";
    default:
      return "http://localhost:3000";
  }
}

/**
 * @param stage
 * @return Cognito Identity pool id for the stage
 */
export function getIdentityPoolId(stage: string): string {
  switch (stage.toLowerCase()) {
    case PERSONEL_DOMAIN:
      return "us-east-1:017091e2-8259-48e1-8c64-a93b4dac93f0";
    case ALPHA_DOMAIN:
      return "us-east-1:b8f3174e-89df-4a92-9695-a845b272cf63";
    case BETA_DOMAIN:
      return "us-east-1:bb794379-76da-4724-9926-417f01c418bf";
    case PROD_DOMAIN:
      return "us-east-1:d5a76df4-5b01-4c1c-a4ff-b7f549b3bd43";
    default:
      return "us-east-1:b8f3174e-89df-4a92-9695-a845b272cf63"; // using alpha backend for default
  }
}

/**
 * @param stage
 * @return Cognito User pool id for the stage
 */
export function getUserPoolId(stage: string): string {
  switch (stage.toLowerCase()) {
    case PERSONEL_DOMAIN:
      return "us-east-1_A54tWP0jI";
    case ALPHA_DOMAIN:
      return "us-east-1_ZiMveAqz3";
    case BETA_DOMAIN:
      return "us-east-1_yqdlO0lif";
    case PROD_DOMAIN:
      return "us-east-1_7770itzku";
    default:
      return "us-east-1_ZiMveAqz3"; // using alpha backend for default
  }
}

/**
 * @param stage
 * @return User pool Web Client id for the stage
 */
export function getUserPoolWebClientId(stage: string): string {
  switch (stage.toLowerCase()) {
    case PERSONEL_DOMAIN:
      return "21utift5o8n88j721hrd505s52";
    case ALPHA_DOMAIN:
      return "4qgp7413smiuofe58e6u62h9h3";
    case BETA_DOMAIN:
      return "1hsif3p0eun16gngbjn7jcedm";
    case PROD_DOMAIN:
      return "57cb8h3iic4vpvs10bgo677s77";
    default:
      return "4qgp7413smiuofe58e6u62h9h3"; // Using alpha backend for default
  }
}

/**
 * @param stage
 * @return Web Orchestrator Lambda function URL
 */
export function getWebOrchestratorEndpoint(stage: string): string {
  switch (stage.toLowerCase()) {
    case PERSONEL_DOMAIN:
      return "https://m5ndwq723nci2chjeboddoycfa0mjjvu.lambda-url.us-east-1.on.aws/";
    case ALPHA_DOMAIN:
      return "https://6wzheoxxvuw47ym35ijre54nsq0ctycs.lambda-url.us-east-1.on.aws/";
    case BETA_DOMAIN:
      return "https://ep7rays3qoevvokbcxigd5ulae0fsdkf.lambda-url.us-east-1.on.aws/";
    case PROD_DOMAIN:
      return "https://r3zcwn5xazzy3hxr4cwfvbwyxu0htfau.lambda-url.us-east-1.on.aws/";
    default:
      return "https://6wzheoxxvuw47ym35ijre54nsq0ctycs.lambda-url.us-east-1.on.aws/"; // Using alpha backend for default
  }
}

/**
 * @param stage
 * @return Web meatdata Lambda function URL
 */
export function getWebMetadataFeedbackEndpoint(stage: string): string {
  switch (stage.toLowerCase()) {
    case PERSONEL_DOMAIN:
      return "https://q5tem26ga6wvvq2hv4bsuo5qa40ptlxl.lambda-url.us-east-1.on.aws/";
    case ALPHA_DOMAIN:
      return "https://ecmee4ueuqou4wlc5kgrjjvfry0wrnsj.lambda-url.us-east-1.on.aws/";
    case BETA_DOMAIN:
      return "https://gxjojdwatkziterjyhn4xf2pwu0ddafg.lambda-url.us-east-1.on.aws/";
    case PROD_DOMAIN:
      return "https://cmmij3ssww6rsn7ee24qt7m7au0mmbsm.lambda-url.us-east-1.on.aws/";
    default:
      return "https://ecmee4ueuqou4wlc5kgrjjvfry0wrnsj.lambda-url.us-east-1.on.aws/"; // Using alpha backend for default
  }
}

/**
 * @param stage
 * @return conversation Lambda function URL
 */
export function getConversationLambdaEndpoint(stage: string): string {
  switch (stage.toLowerCase()) {
    case PERSONEL_DOMAIN:
      return "https://cawgkeoj62dxhmbvafipgcojte0aqwez.lambda-url.us-east-1.on.aws/";
    case ALPHA_DOMAIN:
      return "https://kd2vt4yysglqz3ascjxe7iamha0mmroy.lambda-url.us-east-1.on.aws/"; // Belongs to AWS account 654654442444
    case BETA_DOMAIN:
      return "https://nj6nrqygdhpqkk26zurzmqinoa0gzvcj.lambda-url.us-east-1.on.aws/";
    case PROD_DOMAIN:
      return "https://jx2mctgcxap2zpzkf6zan4xdwe0gjiyd.lambda-url.us-east-1.on.aws/";
    default:
      return "https://7zm3nhpeuvfqophdxntlr4k4qi0qzjsy.lambda-url.us-east-1.on.aws/";
  }
}
