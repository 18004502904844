import React, { FC } from "react";

import { LoadingBar } from "@amzn/awsui-chat-components";
import { Box, Container, SpaceBetween } from "@cloudscape-design/components";
import ReactMarkdown from "react-markdown";

import { ChatMessage, ChatMessageProps } from "./message/ChatMessage";
import finneyLogo from "../../components/image/FinneyLogo.png";
import MarkdownCodeBlockHighlighter from "../../rendering-helpers/MarkdownCodeBlockHighlighter";

interface ChatHistoryProps {
  introText: string;
  messageHistory: ChatMessageProps[];
  chatPanelContents: string;
  isLoading: boolean;
  skill: any;
}

export const ChatHistory: FC<ChatHistoryProps> = ({
  introText,
  messageHistory,
  chatPanelContents,
  isLoading,
  skill,
}) => {
  return (
    <>
      <SpaceBetween direction={"horizontal"} size={"s"}>
        <span className={"image-container"}>
          <img width={32} src={finneyLogo} alt="Finney Logo" />
        </span>
        <ReactMarkdown components={{ code: MarkdownCodeBlockHighlighter }} className={"skill-page"}>
          {introText}
        </ReactMarkdown>
      </SpaceBetween>
      <SpaceBetween direction="vertical" size="m">
        {messageHistory.map((message, idx) => (
          <ChatMessage
            key={idx}
            messageId={message.messageId}
            timestamp={message.timestamp}
            skill={skill.label}
            message={message.message}
            owner={message.owner}
            autoSpeak={message.autoSpeak}
            citations={message.citations}
            referencedSources={message.referencedSources}
            showReportFeedbacks={message.showReportFeedbacks}
            thumbsUp={message.thumbsUp}
            thumbsDown={message.thumbsDown}
            rating={message.rating}
            feedbacks={message.feedbacks}
          />
        ))}
        {!chatPanelContents ? null : (
          <Container>
            <SpaceBetween alignItems="start" direction="horizontal" size="xs">
              <span>
                <img width={32} src={finneyLogo} alt="Finney" />
              </span>
              <SpaceBetween direction="vertical" size="m">
                {isLoading ? (
                  <div aria-live="polite">
                    <Box margin={{ bottom: "xs", left: "l" }} color="text-body-secondary">
                      Generating a response...
                    </Box>
                    <LoadingBar variant="gen-ai" />
                  </div>
                ) : null}

                <div style={{ maxWidth: 1100 }}>
                  <ReactMarkdown children={chatPanelContents} components={{ code: MarkdownCodeBlockHighlighter }} />
                </div>
              </SpaceBetween>
            </SpaceBetween>
          </Container>
        )}
      </SpaceBetween>
    </>
  );
};
