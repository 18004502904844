import React, { ReactNode, useEffect, useState } from "react";

import { Spinner } from "@amzn/awsui-components-react-v3";
import { Amplify, Auth } from "aws-amplify";

import { envConfig } from "../amplify/AmplifyConstants";

interface AuthProviderProps {
  children: ReactNode;
}

export const AuthProvider = ({ children }: AuthProviderProps): JSX.Element => {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const configure = async () => {
      try {
        Amplify.configure(envConfig);
        await Auth.currentAuthenticatedUser();
      } catch (error) {
        console.error(error);
        await Auth.federatedSignIn({ customProvider: "AmazonFederate" });
      } finally {
        setLoading(false);
      }
    };
    configure();
  }, []);

  return loading ? <Spinner size="large" /> : <>{children}</>;
};
